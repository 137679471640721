import { ApolloLink, HttpLink, Operation } from '@apollo/client/core'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { Kind } from 'graphql/language/kinds'

import { customFetchWithOnProgress } from './customFetchWithOnProgress'
import { getDefaultWsClient, getKpiWsClient } from './wsClients'

const hasSubscriptionOperation = ({ query }: Operation) => {
  const definition = getMainDefinition(query)

  return (
    definition.kind === Kind.OPERATION_DEFINITION &&
    definition.operation === 'subscription'
  )
}

const createHttpLink = (uri: string, useFileUpload: boolean) => {
  const options = {
    credentials: 'same-origin',
    uri,
  }

  if (useFileUpload)
    return createUploadLink({
      ...options,
      fetch: customFetchWithOnProgress,
    })

  return new HttpLink(options)
}

const createWsLink = () => {
  return new ApolloLink((operation) => {
    const { useKpiWsConnection } = operation.getContext()

    const link = useKpiWsConnection ? getKpiWsClient() : getDefaultWsClient()

    // Public pages (like login page), for example, have no metadata for the websocket URL exposed
    // That metadata is necessary to provide the connection URL to the websocket client
    if (!link) throw new Error('Websocket client not initialized')

    return link.request(operation)
  })
}

const createApolloLink = (
  uri: string,
  useFileUpload: boolean,
  useWebsockets: boolean
) => {
  if (!useWebsockets) return createHttpLink(uri, useFileUpload)

  return ApolloLink.split(
    hasSubscriptionOperation,
    createWsLink(),
    createHttpLink(uri, useFileUpload)
  )
}

export default createApolloLink
