import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

const MAX_RETRIES = 100
const BASE_RETRY_INTERVAL = 3000
const TAB_CLOSED_RETRY_INTERVAL = 10_000

const getWsURL = () => {
  const urlMetaElem = document.head.querySelector<HTMLMetaElement>(
    `meta[name='action-cable-url']`
  )

  return urlMetaElem?.content
}

const wsURL = getWsURL()

let defaultWsClientInstance: GraphQLWsLink | null = null
let kpiWsClientInstance: GraphQLWsLink | null = null
let retryCount = 0

const options = {
  lazy: false,
  retryAttempts: Infinity,
  retryWait: (retries: number) =>
    new Promise<void>((resolve) => {
      retryCount = retries
      const retryCountBasedIntervalDurationIncrease = retries * 100 //ms

      let timeout =
        BASE_RETRY_INTERVAL + retryCountBasedIntervalDurationIncrease

      if (document.visibilityState != 'visible') {
        timeout =
          TAB_CLOSED_RETRY_INTERVAL + retryCountBasedIntervalDurationIncrease
      }

      // If BE breaks and all clients disconnect, ensure (somewhat) they don't all try to reconnect at the same moment
      const randomizedTimeout = (Math.random() + 1) * timeout

      setTimeout(() => resolve(), randomizedTimeout)
    }),
  shouldRetry: () => (retryCount < MAX_RETRIES ? true : false),
  url: wsURL as string,
}

export function getDefaultWsClient() {
  if (!defaultWsClientInstance && wsURL) {
    defaultWsClientInstance = new GraphQLWsLink(createClient(options))
  }

  return defaultWsClientInstance
}

export function getKpiWsClient() {
  if (!kpiWsClientInstance && wsURL) {
    kpiWsClientInstance = new GraphQLWsLink(createClient(options))
  }

  return kpiWsClientInstance
}
